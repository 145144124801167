<template>
  <div class="p-snackbar-wrapper">
    <div class="p-snackbar">
      <p-icon v-if="severity" size="xmd" v-bind="iconAttributes" />

      <p
        v-if="hasKey(title)"
        class="p-snackbar__description"
        v-html="$t(title?.langkey, title?.params)"
      />

      <p
        v-if="hasKey(link)"
        class="p-snackbar__link"
        @click="navigation"
        v-html="$t(link?.label?.langkey, link?.label?.params)"
      />

      <p-icon size="md" color="succes" icon="close" @on-click="deleteSnackbar" />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  link: {
    type: Object,
    default: () => ({}),
  },
  title: {
    type: Object,
    default: () => ({}),
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  timeout: {
    type: Number,
    default: 3000,
  },
  severity: {
    type: String,
    default: 'info',
    validator: (val: string) => ['info', 'success', 'danger'].includes(val),
  },
})

const iconAttributes = computed(() => {
  const icons = {
    info: { icon: 'info-regular', color: 'base-3' },
    success: { icon: 'circle-check', color: 'success' },
    danger: { icon: 'alert-regular', color: 'danger' },
  } as { [key: string]: { icon: string; color: string } }

  return icons[props.severity]
})

const deleteSnackbar = () => {
  // TODO: delete snackbar
}

const navigation = (): void => {
  const { url, target } = props.link || {}
  if (url.includes('http')) window.open(url, target)
  // else if (target === '_blank') window.open(this.$router.resolve(url).href, target)
  // else this.$router.push(url)
  deleteSnackbar()
}

onMounted(() => {
  !props.persistent && setTimeout(() => deleteSnackbar(), props.timeout)
})
</script>
