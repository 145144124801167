import { handleToken, notifyBy } from './helper'
import handleOtp from './handleOtp'

class Http {
  get(url: string, options = {}) {
    return this.request(url, { method: 'GET', ...options })
  }

  post(url: string, body: any, options = {}) {
    return this.request(url, { method: 'POST', body, ...options })
  }

  put(url: string, body: any, options = {}) {
    return this.request(url, { method: 'PUT', body, ...options })
  }

  delete(url: string, options = {}) {
    return this.request(url, { method: 'DELETE', ...options })
  }

  request(url: string, options = {}) {
    const Authorization = import.meta.client ? (localStorage.getItem('auth-token') as string) : ''
    return new Promise((resolve, reject) => {
      $fetch(url, {
        ...options,
        baseURL: process.env.NUXT_API_BASE_URL,
        headers: {
          'pragma-cache-local': '1231231231231',
          Authorization,
        },
        watch: false,

        onRequest() {
          handleToken()
        },

        onRequestError() {},

        async onResponse({ response }: any) {
          if (response?._data?.payload?.provider && !['auth/sign-in'].includes(url)) {
            const res = await handleOtp(response)
            resolve(res)
          }

          const token = response?._data?.payload?.auth_token
          token && localStorage.setItem('auth-token', token)
          notifyBy(response?._data)
        },

        onResponseError() {},
      })
        .then((response) => {
          resolve(response)
        })
        .catch(({ message }: any) => {
          reject(message)
        })
        .finally(() => {})
    })
  }
}
const $http = new Http()
export { $http }
