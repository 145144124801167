<template>
  <p v-bind="badgeAttributes()" v-text="props.text" />
</template>

<script setup lang="ts">
const props = defineProps({
  size: {
    type: String,
    default: 'md',
    validator: (val: string) => ['sm', 'md', 'lg'].includes(val),
  },
  text: {
    type: [String, Number],
    default: '',
  },
  color: {
    type: String,
    default: 'success',
    validator: (val: string) => ['success', 'danger'].includes(val),
  },
})

const badgeAttributes = () => {
  return {
    class: {
      'p-badge': true,
      'p-badge__text f-utility-10-medium': props.text,
      [`p-badge__indicator--${props.size}`]: props.size,
      [`p-badge__${props.color}`]: props.color,
    },
  }
}
</script>
