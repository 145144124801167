<template>
  <i v-bind="iconAttributes" @click="$emit('on-click')" />
</template>

<script lang="ts" setup>
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: 'fg-accent',
  },
  size: {
    type: String,
    default: 'md',
    validator: (val: string) => ['xs', 'sm', 'xmd', 'md', 'lg', 'xl'].includes(val),
  },
})

defineEmits(['on-click'])

const iconAttributes = computed(() => {
  return {
    class: ['p-icon', `t-${props.color}`, `i-${props.icon}`, `p-icon--${props.size}`],
  }
})
</script>
