<template>
  <div class="side-menu">
    <div class="side-menu__items">
      <p class="f-body-16-medium t-fg-primary" v-text="$t('account.menu.title.account_info')" />
      <div class="side-menu__title-border"></div>
      <div v-for="{ to, title, prepend } in accountMenuItems" :key="title">
        <nuxt-link :to="to" @click="itemClickHandler(to.path)">
          <p-list hover :title="title" :selected="isActive(to.path)">
            <template #prepend>
              <p-icon v-if="prepend" :icon="prepend" size="sm" color="fg-secondary" />
            </template>
            <template #append>
              <p-icon v-if="$device.isMobile" icon="caret-right" size="xs" color="fg-secondary" />
              <p-icon v-if="isActive(to.path)" icon="caret-right" size="xs" color="fg-accent" />
            </template>
          </p-list>
        </nuxt-link>
      </div>
      <p class="f-body-16-medium t-fg-primary mt-20" v-text="$t('account.menu.title.support')" />

      <div class="side-menu__title-border"></div>
      <div v-for="{ to, title, prepend } in supportMenuItems" :key="title">
        <nuxt-link :to="to" @click="itemClickHandler(to.path)">
          <p-list hover :title="title" :selected="isActive(to.path)">
            <template #prepend>
              <p-icon v-if="prepend" :icon="prepend" size="sm" color="fg-secondary" />
            </template>
            <template #append>
              <p-icon v-if="$device.isMobile" icon="caret-right" size="xs" color="fg-secondary" />
              <p-icon v-if="isActive(to.path)" icon="caret-right" size="xs" color="fg-accent" />
            </template>
          </p-list>
        </nuxt-link>
      </div>

      <p-list
        hover
        :title="$t('account.menu.menu_items.log_out')"
        class="cursor-pointer mt-20"
        @on-click="openSignOutOverlay = true"
      >
        <template #prepend>
          <p-icon icon="sign-out" size="sm" color="fg-secondary" />
        </template>
      </p-list>
    </div>

    <sign-out-overlay
      :visibility="openSignOutOverlay"
      @on-close="openSignOutOverlay = false"
      @on-confirm="signOutHandler"
    />
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['on-close'])

const router = useRouter()
const route = useRoute()

const activeItem = ref('')
const openSignOutOverlay = ref(false)

const userStore = useUserStore()

const accountMenuItems = computed((): Record<string, any> => {
  return [
    {
      prepend: 'user-account-profile',
      to: { path: '/account/user-info' },
      title: $t('account.menu.menu_items.user_info'),
    },
    {
      prepend: 'percentage',
      to: { path: '/account/commision-rates' },
      title: $t('account.menu.menu_items.commission_rates'),
    },
    {
      prepend: 'address-book',
      to: { path: '/account/address-book' },
      title: $t('account.menu.menu_items.address_book'),
    },
    {
      prepend: 'security-shield-alt',
      to: { path: '/account/security' },
      title: $t('account.menu.menu_items.security_settings'),
    },
    {
      prepend: 'notifications',
      to: { path: '/account/notifications' },
      title: $t('account.menu.menu_items.notifications'),
    },
    {
      prepend: 'sun-day',
      to: { path: '/account/theme' },
      title: $t('account.menu.menu_items.theme_settings'),
    },
  ]
})

const supportMenuItems = computed((): Record<string, any> => {
  return [
    {
      prepend: 'support',
      to: { path: '/account/help-center' },
      title: $t('account.menu.menu_items.support_center'),
    },
    {
      prepend: 'legal',
      to: { path: '/account/privacy-policies' },
      title: $t('account.menu.menu_items.privacy_policies'),
    },
    {
      prepend: 'news',
      to: { path: '/account/blog' },
      title: $t('account.menu.menu_items.blog'),
    },
  ]
})

const itemClickHandler = (path: string) => {
  activeItem.value = path
  emit && emit('on-close')
}

const isActive = (path: string) => route.path === path

onMounted(() => {
  const currentPath = route.path
  const matchingItem = accountMenuItems.value.find(
    (item: { to: { path: string } }) => item.to.path === currentPath,
  )
  if (matchingItem) {
    activeItem.value = currentPath
  }
})

const signOutHandler = () => {
  userStore.logout()
  router.push('/')
  emit && emit('on-close')
}
</script>
