<template>
  <div>
    <div v-if="$device.isDesktop" class="footer__social--link">
      <p-link
        v-for="(item, index) in paribuLinks"
        :key="`paribuLinks-${index}`"
        size="md"
        target="_blank"
        :label="item.name"
        :href="item.link"
      />
    </div>

    <p-divider class="my-16" />

    <div class="place-items-start gap-24 mt-8">
      <a
        v-for="({ media, link }, index) in medias"
        :key="`media-${index}`"
        :href="link"
        target="_blank"
      >
        <p-icon color="fg-primary" :icon="media" />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
const paribuLinks = [
  {
    name: 'ParibuLog',
    link: 'https://www.paribu.com/blog/',
  },
  {
    name: 'Paribu Hub',
    link: 'https://www.paribu.com/hub/',
  },
  {
    name: 'Team Paribu',
    link: 'https://team.paribu.com/',
  },
  {
    name: 'P-Games',
    link: 'https://www.p-games.io/',
  },
  {
    name: 'Paribu Ventures',
    link: 'https://ventures.paribu.com/',
  },
]

const medias = [
  { media: 'youtube', link: 'https://www.youtube.com/c/Paribu' },
  { media: 'twitter', link: 'https://twitter.com/paribucom' },
  {
    media: 'linkedin',
    link: 'https://www.linkedin.com/company/paribu/',
  },
  { media: 'instagram', link: 'https://www.instagram.com/paribucom/' },
  { media: 'facebook', link: 'https://www.facebook.com/paribucom' },
]
</script>
