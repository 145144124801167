<template>
  <teleport v-if="modalVisibility" to="body">
    <p-overlay @on-close="!persistent && $emit('on-close')">
      <div class="p-modal" v-bind="modalAttributes" :class="props.class">
        <div v-if="$slots.title" class="p-modal__title">
          <slot name="title" />
        </div>
        <div v-if="$slots.description" class="p-modal__description">
          <slot name="description" />
        </div>
        <slot />
      </div>
    </p-overlay>
  </teleport>
</template>

<script setup lang="ts">
defineEmits(['on-close'])

const props = defineProps({
  height: {
    type: String,
    default: 'auto',
  },
  width: {
    type: String,
    default: '',
  },
  relative: {
    type: Boolean,
    default: false,
  },
  fullScreen: {
    type: Boolean,
    default: false,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  modalVisibility: {
    type: Boolean,
    default: false,
  },
  class: {
    type: String,
    default: '',
  },
})

const modalAttributes = computed(() => {
  return {
    class: {
      'p-modal--full-screen': props.fullScreen,
      'p-modal--mobile-width': !props.fullScreen,
      'position-relative': props.relative && !props.fullScreen,
    },
    ...(!props.fullScreen && { style: { height: props.height, width: props.width } }),
  }
})
</script>
