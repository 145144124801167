<template>
  <div class="place-items-center">
    <p-icon icon="loading" v-bind="loadingAttributes" />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  color: {
    type: String,
    default: 'fg-accent',
    validator: (val: string) =>
      ['fg-accent', 'fg-fix-white', 'fg-primary', 'fg-danger'].includes(val),
  },
  size: {
    type: String,
    default: 'sm',
    validator: (val: string) => ['sm', 'md', 'lg'].includes(val),
  },
})

const loadingAttributes = computed((): Record<string, string> => {
  return {
    color: props.color,
    size: props.size,
    class: 'p-loading',
  }
})
</script>
