<template>
  <div class="image-cropper">
    <canvas
      ref="canvas"
      class="image-cropper__canvas"
      @mousedown="startAction"
      @mousemove="onMouseMove"
      @mouseup="endAction"
      @mouseleave="endAction"
    ></canvas>
    <p-button size="lg" class="mt-32" @click="cropImage">
      {{ $t('account.user_detail.modal.buttons.upload') }}
    </p-button>
    <p-button size="lg" button-type="ghost" class="mt-8" @click="cancelUpload">
      {{ $t('account.user_detail.modal.buttons.cancel') }}
    </p-button>
  </div>
</template>

<script setup>
const emit = defineEmits(['cropped-image', 'cancel-upload'])

const props = defineProps({
  choosenImage: {
    type: File,
    required: true,
  },
})

const canvas = ref(null)
const ctx = ref(null)
const image = ref(new Image())
const croppedImage = ref(null)
const cropping = reactive({
  isMoving: false,
  isResizing: false,
  resizingCorner: null,
  startX: 50,
  startY: 50,
  width: 100,
  height: 100,
  minSize: 50,
})

const loadImage = () => {
  const file = props.choosenImage
  const reader = new FileReader()

  reader.onload = (e) => {
    image.value.src = e.target.result
    image.value.onload = () => drawImage()
  }
  reader.readAsDataURL(file)
}

const drawImage = () => {
  if (canvas.value && ctx.value) {
    ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height)

    const aspectRatio = image.value.width / image.value.height
    let srcX, srcY, srcSize

    if (aspectRatio > 1) {
      // Landscape image
      srcSize = image.value.height
      srcX = (image.value.width - srcSize) / 2
      srcY = 0
    } else {
      // Portrait or square image
      srcSize = image.value.width
      srcX = 0
      srcY = (image.value.height - srcSize) / 2
    }

    ctx.value.drawImage(
      image.value,
      srcX,
      srcY,
      srcSize,
      srcSize, // Source rectangle (center square)
      0,
      0,
      320,
      320, // Destination rectangle (entire canvas)
    )

    drawOverlay()
    drawCroppingSquare()
  }
}

const drawOverlay = () => {
  if (ctx.value) {
    ctx.value.fillStyle = 'rgba(0, 0, 0, 0.5)' // Semi-transparent black overlay

    // Top overlay
    ctx.value.fillRect(0, 0, canvas.value.width, cropping.startY)
    // Bottom overlay (adjusted to remove any gaps)
    ctx.value.fillRect(
      0,
      cropping.startY + cropping.height,
      canvas.value.width,
      canvas.value.height - cropping.startY - cropping.height + 1,
    )
    // Left overlay
    ctx.value.fillRect(0, cropping.startY, cropping.startX, cropping.height)
    // Right overlay
    ctx.value.fillRect(
      cropping.startX + cropping.width,
      cropping.startY,
      canvas.value.width - (cropping.startX + cropping.width),
      cropping.height,
    )
  }
}

const drawCroppingSquare = () => {
  if (ctx.value) {
    // Draw the transparent square area
    ctx.value.fillStyle = 'rgba(255, 0, 0, 0)' // Transparent fill for the cropping area
    ctx.value.fillRect(cropping.startX, cropping.startY, cropping.width, cropping.height)

    // Draw corner lines for the square (inside the cropping area)
    const lineLength = 32
    const lineThickness = 5
    const offset = lineThickness / 2 // Offset to make sure the line is inside the square
    ctx.value.strokeStyle = '#FFFFFF40'
    ctx.value.lineWidth = lineThickness

    // Top-left corner
    ctx.value.beginPath()
    ctx.value.moveTo(cropping.startX + offset, cropping.startY + lineLength)
    ctx.value.lineTo(cropping.startX + offset, cropping.startY + offset)
    ctx.value.lineTo(cropping.startX + lineLength, cropping.startY + offset)
    ctx.value.stroke()

    // Top-right corner
    ctx.value.beginPath()
    ctx.value.moveTo(cropping.startX + cropping.width - lineLength, cropping.startY + offset)
    ctx.value.lineTo(cropping.startX + cropping.width - offset, cropping.startY + offset)
    ctx.value.lineTo(cropping.startX + cropping.width - offset, cropping.startY + lineLength)
    ctx.value.stroke()

    // Bottom-left corner
    ctx.value.beginPath()
    ctx.value.moveTo(cropping.startX + offset, cropping.startY + cropping.height - lineLength)
    ctx.value.lineTo(cropping.startX + offset, cropping.startY + cropping.height - offset)
    ctx.value.lineTo(cropping.startX + lineLength, cropping.startY + cropping.height - offset)
    ctx.value.stroke()

    // Bottom-right corner
    ctx.value.beginPath()
    ctx.value.moveTo(
      cropping.startX + cropping.width - lineLength,
      cropping.startY + cropping.height - offset,
    )
    ctx.value.lineTo(
      cropping.startX + cropping.width - offset,
      cropping.startY + cropping.height - offset,
    )
    ctx.value.lineTo(
      cropping.startX + cropping.width - offset,
      cropping.startY + cropping.height - lineLength,
    )
    ctx.value.stroke()
  }
}

const getResizingCorner = (x, y) => {
  const edgeSize = 10
  if (
    x >= cropping.startX - edgeSize &&
    x <= cropping.startX + edgeSize &&
    y >= cropping.startY - edgeSize &&
    y <= cropping.startY + edgeSize
  ) {
    return 'top-left'
  }
  if (
    x >= cropping.startX + cropping.width - edgeSize &&
    x <= cropping.startX + cropping.width + edgeSize &&
    y >= cropping.startY - edgeSize &&
    y <= cropping.startY + edgeSize
  ) {
    return 'top-right'
  }
  if (
    x >= cropping.startX - edgeSize &&
    x <= cropping.startX + edgeSize &&
    y >= cropping.startY + cropping.height - edgeSize &&
    y <= cropping.startY + cropping.height + edgeSize
  ) {
    return 'bottom-left'
  }
  if (
    x >= cropping.startX + cropping.width - edgeSize &&
    x <= cropping.startX + cropping.width + edgeSize &&
    y >= cropping.startY + cropping.height - edgeSize &&
    y <= cropping.startY + cropping.height + edgeSize
  ) {
    return 'bottom-right'
  }
  return null
}

const setCursorStyle = (x, y) => {
  const corner = getResizingCorner(x, y)
  if (corner) {
    switch (corner) {
      case 'top-left':
      case 'bottom-right':
        canvas.value.style.cursor = 'nwse-resize'
        break
      case 'top-right':
      case 'bottom-left':
        canvas.value.style.cursor = 'nesw-resize'
        break
    }
  } else if (
    x >= cropping.startX &&
    x <= cropping.startX + cropping.width &&
    y >= cropping.startY &&
    y <= cropping.startY + cropping.height
  ) {
    canvas.value.style.cursor = 'move'
  } else {
    canvas.value.style.cursor = 'default'
  }
}

const startAction = (event) => {
  const rect = canvas.value.getBoundingClientRect()
  const x = event.clientX - rect.left
  const y = event.clientY - rect.top

  const corner = getResizingCorner(x, y)
  if (corner) {
    cropping.isResizing = true
    cropping.resizingCorner = corner
  } else if (
    x >= cropping.startX &&
    x <= cropping.startX + cropping.width &&
    y >= cropping.startY &&
    y <= cropping.startY + cropping.height
  ) {
    cropping.isMoving = true
    cropping.offsetX = x - cropping.startX
    cropping.offsetY = y - cropping.startY
  }
}

const onMouseMove = (event) => {
  const rect = canvas.value.getBoundingClientRect()
  const x = event.clientX - rect.left
  const y = event.clientY - rect.top

  setCursorStyle(x, y)

  if (!cropping.isMoving && !cropping.isResizing) return

  if (cropping.isMoving) {
    cropping.startX = x - cropping.offsetX
    cropping.startY = y - cropping.offsetY

    // Keep the square within bounds
    cropping.startX = Math.max(0, Math.min(cropping.startX, 320 - cropping.width))
    cropping.startY = Math.max(0, Math.min(cropping.startY, 320 - cropping.height))
  } else if (cropping.isResizing) {
    switch (cropping.resizingCorner) {
      case 'top-left':
        cropping.width += cropping.startX - x
        cropping.height = cropping.width
        cropping.startX = x
        cropping.startY = y
        break
      case 'top-right':
        cropping.width = x - cropping.startX
        cropping.height = cropping.width
        cropping.startY = y
        break
      case 'bottom-left':
        cropping.width += cropping.startX - x
        cropping.height = cropping.width
        cropping.startX = x
        break
      case 'bottom-right':
        cropping.width = x - cropping.startX
        cropping.height = cropping.width
        break
    }

    // Ensure the cropping square stays within the canvas boundaries and respects minimum size
    cropping.width = Math.max(cropping.minSize, Math.min(cropping.width, 320 - cropping.startX))
    cropping.height = cropping.width
    cropping.startX = Math.max(0, Math.min(cropping.startX, 320 - cropping.width))
    cropping.startY = Math.max(0, Math.min(cropping.startY, 320 - cropping.height))
  }

  drawImage()
}

const endAction = () => {
  cropping.isMoving = false
  cropping.isResizing = false
  cropping.resizingCorner = null
}

const cropImage = () => {
  if (ctx.value) {
    const cropCanvas = document.createElement('canvas')
    cropCanvas.width = cropping.width
    cropCanvas.height = cropping.height

    const cropCtx = cropCanvas.getContext('2d')
    // Calculate the scale based on the resized image (320x320) and the original image dimensions
    const scaleX = image.value.width / 320
    const scaleY = image.value.height / 320

    // Draw the cropped area from the original image source
    cropCtx.drawImage(
      image.value,
      cropping.startX * scaleX, // Scaled startX
      cropping.startY * scaleY, // Scaled startY
      cropping.width * scaleX, // Scaled width
      cropping.height * scaleY, // Scaled height
      0,
      0,
      cropping.width,
      cropping.height,
    )

    // Convert the canvas content to a Blob
    croppedImage.value = cropCanvas.toDataURL('image/png')
    cropCanvas.toBlob((blob) => {
      if (blob) {
        // Create a file from the blob
        const file = new File([blob], props.choosenImage.name, { type: 'image/png' })
        emit('cropped-image', file)
      }
    }, 'image/png')
  }
}

const cancelUpload = () => {
  croppedImage.value = null
  emit('cancel-upload')
}

onMounted(() => {
  loadImage()
  if (canvas.value) {
    canvas.value.width = 320 // Fixed size canvas
    canvas.value.height = 320
    ctx.value = canvas.value.getContext('2d')

    // Set the cropping square size to 180x180 and center it
    cropping.width = 180
    cropping.height = 180
    cropping.startX = (canvas.value.width - cropping.width) / 2
    cropping.startY = (canvas.value.height - cropping.height) / 2
  }
})
</script>
