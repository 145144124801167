<template>
  <div class="p-auth-header">
    <div class="p-auth-header__inner container">
      <nuxt-link to="/">
        <nuxt-img src="/icon/paribu-color.svg" width="119" height="24" />
      </nuxt-link>
      <div class="p-auth-header__buttons">
        <p-button size="sm" button-type="ghost" @click="$router.push('/auth/contact-support')">
          Destek iste
          <p-icon size="sm" color="fg-secondary" icon="help" />
        </p-button>
        <p-button size="sm" button-type="ghost" @click="smartRouter.exit()">
          <p-icon size="sm" color="fg-secondary" icon="close" />
        </p-button>
      </div>
    </div>
    <p-divider color="light" class="p-auth-header__divider" />
  </div>
</template>
