<template>
  <p-modal :modal-visibility="!!props.visibility" width="384px" @on-close="onCloseHandler">
    <div class="f-title-20-bold">
      <otp-form :step-data="stepData" @step="onConfirmHandler" />
    </div>
  </p-modal>
</template>

<script lang="ts" setup>
import type { DataInterface } from '~/models'

const props = defineProps<{
  stepData: {
    type: DataInterface
    default: {}
  }
  visibility: boolean
}>()

const emit = defineEmits(['close', 'confirm'])

const openModal = ref(false)

const onCloseHandler = () => {
  openModal.value = false
  emit('close')
}

const onConfirmHandler = (data: DataInterface) => {
  emit('confirm', data)
}
</script>
