// utils/handleOtp.ts
import { useNuxtApp } from '#app'
import OtpOverlay from '@/components/pages/Account/UserInfo/OtpOverlay.vue'

export default function handleOtp(response: any): Promise<any> {
  const nuxtApp = useNuxtApp()
  const { $mountComponent } = nuxtApp as any

  return new Promise((resolve, reject) => {
    const { remove } = $mountComponent(OtpOverlay, {
      stepData: {
        payload: response._data.payload,
      },
      visibility: true,

      onClose: () => {
        remove()
        reject(new Error('Modal Close'))
      },
      onConfirm: (data: any) => {
        remove()
        resolve(data)
      },
    })
  })
}
