<template>
  <p-modal :modal-visibility="props.visibility" width="384px" @on-close="emit('on-close')">
    <div>
      <p class="mb-48 f-title-20-bold t-fg-primary" v-text="$t('account.menu.log_out_overlay')" />
      <div class="place-items-center flex-column gap-8">
        <p-button size="lg" @on-click="emit('on-confirm')">
          {{ $t('account.menu.menu_items.log_out') }}
        </p-button>
        <p-button size="lg" button-type="ghost" @on-click="emit('on-close')">
          {{ $t('general.cancel') }}
        </p-button>
      </div>
    </div>
  </p-modal>
</template>

<script setup lang="ts">
const props = defineProps<{
  visibility: boolean
}>()

const emit = defineEmits(['on-close', 'on-confirm'])
</script>
