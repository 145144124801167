<template>
  <div class="mfa">
    <p-step-title :title="title" :description="description" />
    <div class="mfa__form">
      <p-otp-input
        :key="stepData?.payload?.provider"
        v-model="otp"
        :step-data-changed="stepDataChanged"
        :label="$t('mfa.otp-label')"
        name="otp"
        class="mfa__otp"
        :ttl="tll"
        :error="errorStatus"
        @on-code-again="handleExpire"
      />
      <p-button
        size="lg"
        status="success"
        :loading="loading"
        :disabled="otp.length < 6"
        @click="handleOtp"
      >
        {{ $t('mfa.button') }}
      </p-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DataInterface } from '@/models/user'

const otp = ref('')
const errorStatus = ref(false)

const loading = ref(false)
const userStore = useUserStore()
const stepDataChanged = ref(false)

const emit = defineEmits(['step'])

const props = defineProps<{
  stepData: DataInterface
}>()

const title = computed(() => {
  if (props?.stepData?.title) {
    return props.stepData.title
  }

  if (props.stepData?.payload.provider === 'sms') {
    return $t('mfa.sms')
  } else {
    return $t('mfa.g2fa')
  }
})

const tll = computed(() => {
  if (props.stepData?.payload.provider === 'g2fa') {
    return undefined
  } else {
    return props.stepData.payload.ttl
  }
})

const description = computed(() => {
  if (props?.stepData?.description) {
    return props.stepData.description
  }
  const description = {
    sms: $t('mfa.description-sms', {
      phone: props.stepData.phoneNumber || userStore.user.info.mobile,
    }),
    email: $t('mfa.description-email', {
      phone: props.stepData.phoneNumber || userStore.user.info.mobile,
    }),
    g2fa: $t('mfa.description-g2fa'),
  } as Record<string, any>

  return description[props.stepData.payload?.provider]
})

watch(
  () => props.stepData.payload,
  () => {
    otp.value = ''
    stepDataChanged.value = !stepDataChanged.value
  },
  { deep: true },
)

const handleOtp = () => {
  loading.value = true
  const payload = {
    code: otp.value,
    token: props.stepData.payload.verify_token,
  }

  userStore
    .mfaVerify(payload)
    .then((res) => {
      if (res.payload.auth_token) {
        userStore.fetchUser()
        smartRouter.exit()
      } else if (res.payload.provider) {
        emit('step', { step: props.stepData.step, payload: res.payload })
      } else {
        emit('step', { step: props.stepData.step + 1, payload: res.payload })
      }
    })
    .catch(() => {
      errorStatus.value = true
    })
    .finally(() => {
      loading.value = false
    })
}

const handleExpire = () => {
  userStore.mfaResend({ token: props.stepData.payload.verify_token })
}
</script>
