import { useUserStore } from '#imports'
import locale from '@/locales'

const getBaseCurrency = () => {
  const userStore = useUserStore()
  return userStore.getBaseCurrency
}

const userLocale = () => {
  return locale().locale || 'tr'
}

const isUserAuthenticated = () => {
  const userStore = useUserStore()
  return userStore.isUserAuthenticated
}

export { getBaseCurrency, userLocale, isUserAuthenticated }
