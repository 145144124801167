<template>
  <p-modal
    width="384px"
    :modal-visibility="!!status"
    class="p-sign-up-modal__modal"
    @on-close="closeModal"
  >
    <div class="p-sign-up-modal">
      <template v-if="status === 'referral'">
        <img src="~/assets/icons/signup-referral.svg" />
        <h1 class="f-title-bold-32 t-fg-fix-white" v-html="$t('signUp.id.success.title')" />
        <p class="f-title-body-16 t-fg-fix-white" v-html="$t('signUp.id.success.desc_referral')" />
      </template>
      <template v-else-if="status === 'register_campaign'">
        <img src="~/assets/icons/signup-referral.svg" />
        <h1 class="f-title-bold-32 t-fg-fix-white" v-html="$t('signUp.id.success.title')" />
        <p class="f-title-body-16 t-fg-fix-white" v-html="$t('signUp.id.success.desc_gift')" />
      </template>
      <template v-else>
        <img class="overview__reference__icon" src="~/assets/icons/signup-regular.svg" />
        <h1 class="f-title-bold-32 t-fg-fix-white" v-html="$t('signUp.id.success.title')" />
        <p class="f-title-body-16 t-fg-fix-white" v-html="$t('signUp.id.success.desc')" />
      </template>

      <template v-if="status === 'regular'">
        <p-button size="lg" class="p-sign-up-modal__button" @click="console.log('tl yatıra git')">
          {{ $t('general.button.depositTL') }}
        </p-button>
      </template>
      <template v-else>
        <p-button size="lg" class="p-sign-up-modal__button" @click="closeModal">
          {{ $t('general.okay') }}
        </p-button>
      </template>
    </div>
  </p-modal>
</template>

<script lang="ts" setup>
const userStore = useUserStore()

const status = ref<string | null>('')

watch(
  () => userStore.getShowSignUpModal,
  (value) => {
    if (value || value === null) status.value = value
  },
)

const closeModal = () => {
  userStore.toggleSignUpModal(null)
}
</script>
