import BigJS from 'big.js'

//  0 Big.roundDown      Rounds towards zero.
//  1 Big.roundHalfUp   Rounds towards nearest neighbor.
//  2 Big.roundHalfEven Rounds towards nearest neighbor.
//  3 Big.roundUp       Rounds away from zero.

type RM = { down: number; halfUp: number; halfEven: number; up: number }
const RM: RM = { down: 0, halfUp: 1, halfEven: 2, up: 3 }

type MD = Partial<{
  numA: number
  numB: number
  precision: number
  round: string
  fallback: string | number
}>

//  @param {number} a
//  @param {number} b
//  @param {number} precision - Number of decimal places
//  @param {string} round - Round direction
//  @returns {Number}

function mul({ numA, numB, precision = 2, round = 'down', fallback = '' }: MD = {}): any {
  if (typeof numA !== 'number' || typeof numB !== 'number') return fallback

  return Number(
    BigJS(numA)
      .mul(BigJS(numB))
      .toFixed(precision, (RM as any)[round]),
  )
}

//  @param {number} a
//  @param {number} b
//  @param {number} precision - Number of decimal places
//  @param {string} round - Round direction
//  @returns {string}

function div({ numA, numB, precision = 2, round = 'down', fallback = '' }: MD = {}): any {
  if (!isNumber(numA as number) || !isNumber(numB as number)) return fallback
  return BigJS(numA as number)
    .div(BigJS(numB as number))
    .toFixed(precision, (RM as any)[round])
}

// a/100 = x/b
// @param {number} a
// @param {number} b
// @param {number} precision - Number of decimal places
// @param {string} round - Round direction
// @returns {string}

function mulPercent({ numA, numB, precision = 2, round = 'down', fallback = '' }: MD = {}): any {
  if (!isNumber(numA as number) || !isNumber(numB as number)) return fallback
  return BigJS(BigJS(numA as number).mul(BigJS(numB as number)))
    .div(100)
    .toFixed(precision, (RM as any)[round])
}

// a/b = x/100
// @param {number} a
// @param {number} b
// @param {number} precision - Number of decimal places
// @param {string} round - Round direction
// @returns {string}

function getPercent({ numA, numB, precision = 2, round = 'down', fallback = '' }: MD = {}): any {
  if (!isNumber(numA as number) || !isNumber(numB as number)) return fallback
  return BigJS(BigJS(numA as number).mul(100))
    .div(BigJS(numB as number))
    .toFixed(precision, (RM as any)[round])
}

//  @param {number} a
//  @param {number} b
//  @returns {string}

function addPercent({ numA, numB, fallback = '' }: MD = {}): any {
  if (!isNumber(numA as number) || !isNumber(numB as number)) return fallback
  const delta = mulPercent({ numA, numB: Math.abs(Number(numB)) })
  return (numB as number) > 0
    ? BigJS(numA as number).plus(BigJS(delta))
    : BigJS(numA as number).minus(BigJS(delta))
}

//  @param {Array} numbers - Array of numbers
//  @param {number} precision - Number of decimal places
//  @param {boolean} prettify - If true, returns formatted currency
//  @returns {string}
//  @example: sum([4.987, 2.5, 3.5]) // '9,00'

type Plus = Partial<{ numbers: number[] | string[]; precision: number }>

function plus({ numbers = [], precision = 2 }: Plus = {}): number {
  let result = 0
  numbers.forEach((number: number | string) => {
    if (isNumber(number)) result = Number(BigJS(result).plus(BigJS(number)).toFixed(precision))
  })

  return result
}

//  @param {number} a
//  @param {number} b
//  @param {number} precision - Number of decimal places
//  @param {string} round - Round direction
//  @returns {Number}

function minus({ numA, numB, precision = 2, round = 'down', fallback = '' }: MD = {}): any {
  if (!isNumber(numA as any) || !isNumber(numB as any)) return fallback
  return Number(
    BigJS(numA as number)
      .minus(BigJS(numB as number))
      .toFixed(precision, (RM as any)[round]),
  )
}

//  @param {number} number - Number to round
//  @param {number} precision - Number of decimal places
//  @param {string} round - Round direction
//  @returns {string} - Rounded number
//  @example: round({number: 4.987, precision: 2, round: 'up'}) // '4.99'

type Round = Partial<{ number: number; precision: number; round: string }>

function round({ number = 0, precision = 2, round = 'down' }: Round = {}): number {
  return isNumber(number) ? Number(BigJS(number).toFixed(precision, (RM as any)[round])) : number
}

//  @param {number} number - Number to round
//  @param {string} step - steps to round
//  @param {number} precision - Number of decimal places
//  @param {string} round - Round direction
//  @returns {string} - Rounded number snapped to step
//  @example: round({number: 4.987, step: "0.02", precision: 2, round: 'down'}) // '4.98'

type Step = Partial<{ number: number; step: string; precision: number; round: string }>

function step({ number = 0, step = '0.01', precision = 2, round = 'down' }: Step = {}): number {
  if (!isNumber(number)) return number

  const delta = BigJS(number).mod(BigJS(step))
  return BigJS(number)
    .minus(BigJS(delta))
    .plus(round === 'up' && delta > 0 ? BigJS(step) : 0)
    .toFixed(precision, RM[round])
}

//  @param {any} number - Value to check
//  @returns {boolean} - True if number is a number
//  @example: isNumber(4.987) // true

function isNumber(number: string | number): boolean {
  const numberWithDot = String(number).replace(',', '.')
  try {
    return !!new BigJS(numberWithDot)
  } catch (e) {
    return false
  }
}

export { mul, div, mulPercent, getPercent, plus, minus, round, step, isNumber, addPercent }
