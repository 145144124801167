<template>
  <div>
    <div class="header__profile--wrapper__hover">
      <div class="header__profile--wrapper__hover--container">
        <user-details />
        <p-divider class="mt-24 mb-16" />

        <div v-for="{ to, title, prepend } in accountHoverMenuItems" :key="title">
          <nuxt-link :to="to">
            <p-list hover :title="title">
              <template #prepend>
                <p-icon v-if="prepend" :icon="prepend" size="sm" color="fg-secondary" />
              </template>
              <template #append>
                <p-icon
                  icon="caret-right"
                  size="xs"
                  :color="isActive(to?.path) ? 'fg-accent' : 'fg-secondary'"
                />
              </template>
            </p-list>
          </nuxt-link>
        </div>

        <p-list
          hover
          :title="$t('account.menu.menu_items.log_out')"
          class="cursor-pointer mt-20"
          @click="openSignOutOverlay = true"
        >
          <template #prepend>
            <p-icon icon="sign-out" size="sm" color="fg-secondary" />
          </template>
          <template #append>
            <p-icon icon="caret-right" size="xs" color="fg-accent" />
          </template>
        </p-list>

        <sign-out-overlay
          :visibility="openSignOutOverlay"
          @on-close="openSignOutOverlay = false"
          @on-confirm="signOutHandler"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()
const router = useRouter()

const userStore = useUserStore()

const openSignOutOverlay = ref(false)

const signOutHandler = () => {
  userStore.logout()
  router.push('/')
}

const isActive = (path: string) => route.path === path
const accountHoverMenuItems = computed((): Record<string, any> => {
  return [
    {
      prepend: 'user-account-profile',
      to: { path: '/account/user-info' },
      title: $t('account.menu.menu_items.user_info'),
    },
    {
      prepend: 'address-book',
      to: { path: '/account/address-book' },
      title: $t('account.menu.menu_items.address_book'),
    },
    {
      prepend: 'security-shield-alt',
      to: { path: '/account/security' },
      title: $t('account.menu.menu_items.security_settings'),
    },
    {
      prepend: 'support',
      to: { path: '/account/help-center' },
      title: $t('account.menu.menu_items.support_center'),
    },
    {
      prepend: 'news',
      to: { path: '/account/blog' },
      title: $t('account.menu.menu_items.blog'),
    },
  ]
})
</script>
