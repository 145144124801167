import { createApp, h } from 'vue'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('mountComponent', (Component: any, props = {}) => {
    const container = document.createElement('div')
    document.body.appendChild(container)

    const app = createApp({
      render: () => h(Component, props),
    })

    // Ana uygulamanın bağlamını kopyalayın
    app.config.globalProperties = nuxtApp.vueApp.config.globalProperties
    app._context.provides = {
      ...nuxtApp.vueApp._context.provides,
      ...app._context.provides,
    }

    app.mount(container)

    const remove = () => {
      app.unmount()
      document.body.removeChild(container)
    }

    return { remove, app }
  })
})
