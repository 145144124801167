<template>
  <div class="otp">
    <div class="place-items-center gap-12">
      <input
        v-for="(_, index) in props.length"
        :id="`otp-${index}`"
        :key="index"
        ref="inputRefs"
        v-model="inputValue[index]"
        :class="inputAttributes(index)"
        type="text"
        maxlength="1"
        @keydown="pressHandler($event, index)"
        @input="inputHandler($event, index)"
        @focusin="focusHandler(index)"
        @focusout="focusedInput = undefined"
      />
    </div>
    <p-otp-counter
      v-if="props?.ttl"
      :ttl="props?.ttl"
      @on-code-again="emit('on-code-again')"
      @on-expire="emit('on-expire')"
    />
    <p v-if="errorStatus" class="f-utility-12 t-fg-danger">
      {{ $t('mfa.otp-error') }}
    </p>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['on-expire', 'on-code-again'])

const inputValue = ref<string[]>([])
const inputRefs = ref<HTMLInputElement[]>([])
const focusedInput = ref<number | undefined>(undefined)
const errorStatus = ref(false)

const modelValue = defineModel<String>('modelValue', { default: '' })

const props = defineProps({
  length: {
    type: Number,
    default: 6,
  },
  ttl: {
    type: Number,
    default: undefined,
  },
  error: {
    type: Boolean,
    default: false,
  },
  stepDataChanged: {
    type: Boolean,
    default: false,
  },
})

watch(
  () => props.stepDataChanged,
  (newValue) => {
    if (newValue) {
      inputValue.value = Array.from({ length: props.length }, () => '')
    }
  },
)

watch(
  inputValue,
  (value) => {
    modelValue.value = value.join('')
  },
  { deep: true },
)

watch(
  () => props.error,
  (value) => {
    errorStatus.value = value
  },
)

const pasteHandler = () => {
  navigator.clipboard.readText().then((text) => {
    const clippedText = text.slice(0, props.length)
    if (!/^\d+$/.test(clippedText)) {
      return
    }
    inputValue.value = clippedText.split('')
  })
}

const inputHandler = (event: Event, index: number) => {
  event.preventDefault()
  const target = event.target as HTMLInputElement

  const inputLength = inputValue.value[index].length

  // check for non-numeric values
  if (!/\d/.test(target.value)) {
    target.value = ''
    return
  }

  // check for input length and focus on next input
  if (inputLength > 0) {
    if (index !== props.length - 1) {
      inputRefs.value[index + 1].focus()
      inputRefs.value[index + 1].select()
    } else {
      inputRefs.value[index].select()
    }
  } else {
    inputValue.value[index] = target.value
  }
}

const pressHandler = (event: KeyboardEvent, index: number) => {
  if (event.key === 'Backspace') {
    event.preventDefault()
    inputValue.value[index] = ''
    if (index === 0) {
      return
    }
    inputRefs.value[index - 1].focus()
  }

  // catch paste event mac and windows
  if (event.key === 'v' && (event.metaKey || event.ctrlKey)) {
    event.preventDefault()
    pasteHandler()
  }

  // catch delete event on mac and windows and delete forward
  if (event.key === 'Delete') {
    event.preventDefault()
    inputValue.value[index] = ''
    if (index === props.length - 1) {
      return
    }
    inputRefs.value[index + 1].focus()
  }
}

const focusHandler = (index: number) => {
  inputRefs.value[index].select()
  focusedInput.value = index
  errorStatus.value = false
}

const inputAttributes = (index: number) => {
  if (errorStatus.value) {
    return 'otp__input otp__input__error'
  } else {
    return focusedInput.value === index
      ? 'otp__input otp__input__active'
      : 'otp__input otp__input__deactive'
  }
}

onMounted(() => {
  inputValue.value = Array.from({ length: props.length }, () => '')
})
</script>
