<template>
  <div class="p-auth-layout">
    <PHeader />
    <main>
      <div class="container">
        <div class="row">
          <div class="offset-sm-4 col-12 col-sm-4">
            <slot />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import Header from './Header.vue'
export default {
  components: {
    PHeader: Header,
  },
}
</script>
