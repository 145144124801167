export default defineNuxtRouteMiddleware((to, from) => {
  if (to.fullPath !== from.fullPath && import.meta.client) {
    window.scrollTo(0, 0)
  }

  // todo: find a better way
  if (import.meta.client) {
    const mainStore = useMainStore()
    mainStore.setLocaleMessages()
  }
})
