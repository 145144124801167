<template>
  <div>
    <header :class="['header', isSticky && 'header__sticky']">
      <div :class="['place-items-between', isMarketsPage ? 'container-lg' : 'container']">
        <nuxt-link to="/" class="place-items-center">
          <nuxt-img src="/icon/paribu-color.svg" width="119" height="24" />
        </nuxt-link>

        <nav v-if="$device.isMobile">
          <p-icon icon="hamburger" size="xs" color="fg-primary" @on-click="showMobileMenu = true" />
        </nav>

        <nav v-else class="header__list">
          <div class="place-items-between gap-16">
            <nuxt-link to="/" class="header__list--link">{{ $t('general.home') }}</nuxt-link>
            <nuxt-link to="/markets" class="header__list--link">{{
              $t('general.markets')
            }}</nuxt-link>
            <nuxt-link
              v-if="!isUserAuthenticated()"
              :to="announcementsOverlay"
              class="header__list--link header__link--notactive"
            >
              {{ $t('general.announcements') }}
            </nuxt-link>
            <nuxt-link v-if="isUserAuthenticated()" to="/wallet" class="header__list--link">
              {{ $t('general.wallet') }}
            </nuxt-link>
          </div>

          <div>
            <div>
              <nuxt-link
                v-if="!isUserAuthenticated()"
                class="header__list--link place-items-between gap-8 px-16"
                @click="smartRouter.go('/auth/sign-in')"
              >
                {{ $t('general.signin') }}
                <p-icon icon="user" size="sm" color="fg-primary" />
              </nuxt-link>

              <div v-else class="place-items-between gap-8">
                <nuxt-link
                  :to="notificationsOverlay"
                  class="header__link--notactive header__notification--link"
                >
                  <div class="header__notification">
                    <p-icon icon="notifications" size="sm" color="fg-primary" />
                    <p-badge
                      v-if="notificationCount"
                      :text="notificationCount"
                      color="success"
                      size="lg"
                    />
                  </div>
                </nuxt-link>
                <nuxt-link
                  :to="$device.isDesktop ? '/account/user-info' : '/account'"
                  class="header__link--notactive header__profile--link"
                >
                  <div class="header__profile--wrapper">
                    <div class="header__profile">
                      <img
                        src="../../assets/images/account/profilePicture.png"
                        class="user-detail__avatar__img"
                        alt="user profile picture"
                      />
                    </div>
                    <account-hover />
                  </div>
                </nuxt-link>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div v-if="showMobileMenu && $device.isMobile">
        <mobile-navigation @on-close="showMobileMenu = false" />
      </div>
    </header>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import MobileNavigation from './MobileNavigation/index.vue'

const { isSticky } = useSticky(0)

const route = useRoute()
const isMarketsPage = ref(false)
const notificationCount = ref('2')
const showMobileMenu = ref(false)
const announcementsOverlay = ref({
  path: route.path,
  query: { overlay: 'announcements' },
})

const notificationsOverlay = ref({
  path: route.path,
  query: { overlay: 'notifications' },
})

watch(
  () => [route.name, route.path],
  ([newName, newPath]) => {
    isMarketsPage.value = (newName as string)?.includes('markets-')

    announcementsOverlay.value = {
      path: typeof newPath === 'string' ? newPath : '/',
      query: { overlay: 'announcements' },
    }

    notificationsOverlay.value = {
      path: typeof newPath === 'string' ? newPath : '/',
      query: { overlay: 'notifications' },
    }
  },
  { immediate: true },
)
</script>
