import { defineStore } from 'pinia'
import jsonmergepatch from 'json-merge-patch'
import type {
  MarketState,
  MainState,
  ObjectRecord,
  OrderbookList,
  OrderConfig,
  PromiseAny,
} from '~/models'
import Pusher from '@/plugins/pusher'

export const useMarketStore = defineStore('MarketStore', {
  // MARKET STATE
  state: (): MarketState => {
    return {
      marketData: {},
      userSelectedOrder: {},
      interval: 'percentage',
      basicChartInterval: '1D',
      sortKey: 'volume_total',
      sortOrder: {
        volume_total: 'desc',
        name: 'desc',
        price: 'desc',
        change: 'desc',
      },
      orderFilters: {
        processes: [],
        currencies: [],
        started_at: '',
        ended_at: '',
        period: 'all',
      },
      latestMatches: [],
      orderbook: {},
    } as MarketState
  },

  // MARKET ACTIONS
  actions: {
    fetchMarketData(marketName: string) {
      this.marketFetcher(marketName)
      this.marketSubscriber(marketName)
    },

    async marketFetcher(marketName: string): Promise<void> {
      const [latestMatchesResponse, orderbookResponse] = await Promise.all([
        $fetch(`${process.env.NUXT_API_BASE_URL}/market/${marketName}/latest-matches`),
        $fetch(`${process.env.NUXT_API_BASE_URL}/market/${marketName}/orderbook`),
      ])

      const latestMatchesData = latestMatchesResponse?.payload
      const orderbookData = orderbookResponse?.payload

      this.marketData[marketName] = {
        latest_matches: latestMatchesData,
        orderbook: orderbookData,
      }

      const authToken = localStorage.getItem('auth-token')
      if (authToken) {
        const userOrdersResponse: ObjectRecord = await $http.get(
          `${process.env.NUXT_API_BASE_URL}/user/market/${marketName}/orders`,
        )
        this.marketData[marketName].orders = userOrdersResponse?.payload
      }
    },

    marketSubscriber(marketName: string): void {
      Pusher.subscribe({
        payload: { marketName },
        mutation: 'mergeOrderbook',
        store: 'marketStore',
        channel: `cache-market-${marketName}-orderbook`,
      })

      Pusher.subscribe({
        payload: { marketName },
        mutation: 'mergeLatestMatches',
        store: 'marketStore',
        channel: `cache-market-${marketName}-latest-matches`,
      })
    },

    mergeOrderbook(payload: ObjectRecord): void {
      if (payload.action === 'merge') {
        const patch = jsonmergepatch.apply(
          this.marketData[payload.marketName].orderbook,
          payload.payload,
        )
        $_.set(this.marketData, [payload.marketName, 'orderbook'], patch)
      }
      if (payload.action === 'replace') {
        $_.set(this.marketData, [payload.marketName, 'orderbook'], payload.payload)
      }
    },

    mergeLatestMatches(payload: ObjectRecord): void {
      if (payload.action === 'merge') {
        const patch = jsonmergepatch.apply(
          this.marketData[payload.marketName].latest_matches,
          payload.payload,
        )
        $_.set(this.state, [payload.marketName, 'latest_matches'], patch)
      }
      if (payload.action === 'replace') {
        $_.set(this.state, [payload.marketName, 'latest_matches'], payload.payload)
      }
    },

    cancelOrder(payload: OrderConfig): PromiseAny {
      return $http.post('orders/cancel', payload)
    },

    fetchChartData(query: ObjectRecord): PromiseAny {
      return $http.get(`chart/history`, {
        query,
      })
    },

    fillOutTradeForm(order: OrderConfig): void {
      this.userSelectedOrder = order
    },

    order(order: OrderConfig): PromiseAny {
      return $http.post('orders', order)
    },
  },

  // MARKET GETTERS
  getters: {
    getBestOrderPrice:
      (state: MarketState) =>
      (marketName: string, trade: string): string | null | undefined => {
        const orderbook =
          state.marketData[marketName] &&
          state.marketData[marketName]?.orderbook?.[trade === 'buy' ? 'sell' : 'buy']
        if (!orderbook) return null
        const min = $_.minBy(Object.keys(orderbook), (o) => +o)
        const max = $_.maxBy(Object.keys(orderbook), (o) => +o)
        return trade === 'sell' ? max : min
      },

    getLatestMatches:
      (state: MarketState) =>
      (marketName: string): any => {
        return (
          state.marketData[marketName] &&
          $_.orderBy(state.marketData[marketName]?.latest_matches, 'timestamp', 'desc')
        )
      },

    getOrderbook:
      (state: MarketState, getter: ObjectRecord, rootState: MainState) =>
      ({ market, type, sort = 'desc', slice = 15 }: ObjectRecord): OrderbookList => {
        let previousRowsAmount = 0
        const userOpenOrders = $_.map(
          rootState?.user?.open_orders,
          (o: OrderConfig) => o.market === market && o.trade === type && Number(o.price),
        )
        let orderbook = state.marketData[market] && state.marketData[market]?.orderbook?.[type]
        orderbook = $_.chain(orderbook)
          .map((amount, price) => ({ price, amount }))
          .orderBy((o) => +o.price, type === 'buy' ? 'desc' : 'asc')
          .slice(0, slice)
          .map((order) => {
            previousRowsAmount += Number(order.amount)
            $_.set(order, 'previousRowsAmount', previousRowsAmount)
            $_.set(order, 'userHasOrder', userOpenOrders.includes(Number(order?.price) as any))
            return order
          })
          .orderBy((o) => +o.price, sort)
          .value()

        return orderbook
      },

    getUserSelectedOrder: (state: MarketState) => state.userSelectedOrder,

    getUserOrders:
      (state: MarketState) =>
      (marketName: string): OrderConfig[] => {
        return $_.orderBy(state.marketData[marketName]?.orders, 'created_at', 'desc')
      },
  },
})
