<template>
  <div class="user-detail">
    <div class="user-detail__avatar">
      <img
        v-if="croppedImage"
        :src="croppedImage"
        class="user-detail__avatar__img"
        alt="user profile picture"
      />

      <img
        v-else
        src="../../../../assets//images/account/profilePicture.png"
        class="user-detail__avatar__img"
        alt="user profile picture"
      />
      <div class="user-detail__avatar__photo" @click="photoHandler">
        <p-icon icon="camera-photo" color="fg-primary" size="xs" />
      </div>
    </div>
    <div class="user-detail__info">
      <p
        class="user-detail__info__name f-body-16-bold t-fg-primary"
        v-text="userStore.getUser?.info?.name"
      />
      <div class="user-detail__info__verify">
        <p-icon
          :icon="accountVerified ? 'shield-verified' : 'shield-unverified'"
          :color="accountVerified ? 'fg-accent' : 'fg-warning'"
          size="sm"
        />
        <span
          class="f-body-14-bold"
          :class="accountVerified ? 't-fg-accent' : 't-fg-warning'"
          v-text="
            accountVerified
              ? $t('account.user_detail.verified')
              : $t('account.user_detail.unverified')
          "
        />
      </div>
    </div>
    <p-modal
      :modal-visibility="openPhotoModal"
      width="384"
      :persistent="true"
      @on-close="cancelUpload"
    >
      <div class="user-detail__photo-modal">
        <p class="f-title-20-bold t-fg-primary" v-text="$t('account.user_detail.modal.title')" />
        <image-cropper
          :choosen-image="choosenImage"
          @cropped-image="imageUpload"
          @cancel-upload="cancelUpload"
        />
      </div>
    </p-modal>
  </div>
</template>

<script lang="ts" setup>
const userStore = useUserStore()

const openPhotoModal = ref(false)
const choosenImage = ref<File | undefined>(undefined)
const croppedImage = ref<string | null>(null)

const accountVerified = computed(() => userStore.getUser?.info?.security?.is_email_verified)

const photoHandler = () => {
  const fileInput = document.createElement('input')
  fileInput.type = 'file'
  fileInput.accept = 'image/*'
  fileInput.onchange = (event: Event) => {
    const target = event.target as HTMLInputElement
    if (target.files && target.files.length > 0) {
      const file = target.files[0]
      choosenImage.value = file
      openPhotoModal.value = true
    }
  }
  fileInput.click()
}

const imageUpload = (image: File) => {
  openPhotoModal.value = false
  croppedImage.value = URL.createObjectURL(image)
  // todo: upload photo etc
}

const cancelUpload = () => {
  openPhotoModal.value = false
  choosenImage.value = undefined
}
</script>
