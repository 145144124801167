import QRCode from 'qrcode'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      qrcode: QRCode,
    },
  }
})
