import introJs from 'intro.js'
import 'intro.js/introjs.css'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      intro: introJs,
    },
  }
})
