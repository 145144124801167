
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexiJvtk9Lq1yMeta } from "/opt/buildhome/repo/pages/account/address-book/index.vue?macro=true";
import { default as indexVhkxEqJhIuMeta } from "/opt/buildhome/repo/pages/account/blog/index.vue?macro=true";
import { default as indexw2JZVHvVw0Meta } from "/opt/buildhome/repo/pages/account/commision-rates/index.vue?macro=true";
import { default as index1kgMXWjQ8YMeta } from "/opt/buildhome/repo/pages/account/help-center/index.vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as indexCkK7sGpy5SMeta } from "/opt/buildhome/repo/pages/account/notifications/index.vue?macro=true";
import { default as index5FNg4DhqLdMeta } from "/opt/buildhome/repo/pages/account/privacy-policies/index.vue?macro=true";
import { default as indexuZy7x5W3BsMeta } from "/opt/buildhome/repo/pages/account/security/index.vue?macro=true";
import { default as indexjYAK66wxExMeta } from "/opt/buildhome/repo/pages/account/theme/index.vue?macro=true";
import { default as indexP3CvtyAcu6Meta } from "/opt/buildhome/repo/pages/account/user-info/index.vue?macro=true";
import { default as indexkXii3v7yMHMeta } from "/opt/buildhome/repo/pages/auth/contact-support/index.vue?macro=true";
import { default as indexXDkuNSxKaRMeta } from "/opt/buildhome/repo/pages/auth/forgot-password/index.vue?macro=true";
import { default as index8oOKunnnMCMeta } from "/opt/buildhome/repo/pages/auth/sign-in/index.vue?macro=true";
import { default as indexFFhqQ97vMkMeta } from "/opt/buildhome/repo/pages/auth/sign-up/index.vue?macro=true";
import { default as indexECaJn9m6bSMeta } from "/opt/buildhome/repo/pages/donate/index.vue?macro=true";
import { default as indexF6xcYaiah7Meta } from "/opt/buildhome/repo/pages/financial-history/buy-sell/index.vue?macro=true";
import { default as indexNWvERdQCKMMeta } from "/opt/buildhome/repo/pages/financial-history/convert/index.vue?macro=true";
import { default as indexZCcwSUDjahMeta } from "/opt/buildhome/repo/pages/financial-history/deposit-withdraw/index.vue?macro=true";
import { default as indexSmAzS8OMLtMeta } from "/opt/buildhome/repo/pages/financial-history/donation/index.vue?macro=true";
import { default as indexW3qiD79SRMMeta } from "/opt/buildhome/repo/pages/financial-history/fan-apps/index.vue?macro=true";
import { default as indexQG76ckr6btMeta } from "/opt/buildhome/repo/pages/financial-history/prize/index.vue?macro=true";
import { default as indexITwvrwpvE4Meta } from "/opt/buildhome/repo/pages/financial-history/qr/index.vue?macro=true";
import { default as indexCo48HbwdEnMeta } from "/opt/buildhome/repo/pages/financial-history/stake/index.vue?macro=true";
import { default as indexCMVanMCzFOMeta } from "/opt/buildhome/repo/pages/index/index.vue?macro=true";
import { default as index1SZyy76jSaMeta } from "/opt/buildhome/repo/pages/markets/[marketName]/index.vue?macro=true";
import { default as index66F7hWW3KyMeta } from "/opt/buildhome/repo/pages/markets/index.vue?macro=true";
import { default as indexhEXjY4RWvEMeta } from "/opt/buildhome/repo/pages/overlayContent/index.vue?macro=true";
import { default as index6eeNabNZ2SMeta } from "/opt/buildhome/repo/pages/wallet/index.vue?macro=true";
export default [
  {
    name: "account-address-book",
    path: "/account/address-book",
    meta: indexiJvtk9Lq1yMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/address-book/index.vue")
  },
  {
    name: "account-blog",
    path: "/account/blog",
    meta: indexVhkxEqJhIuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/blog/index.vue")
  },
  {
    name: "account-commision-rates",
    path: "/account/commision-rates",
    meta: indexw2JZVHvVw0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/account/commision-rates/index.vue")
  },
  {
    name: "account-help-center",
    path: "/account/help-center",
    meta: index1kgMXWjQ8YMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/help-center/index.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  },
  {
    name: "account-notifications",
    path: "/account/notifications",
    meta: indexCkK7sGpy5SMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/notifications/index.vue")
  },
  {
    name: "account-privacy-policies",
    path: "/account/privacy-policies",
    meta: index5FNg4DhqLdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/privacy-policies/index.vue")
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: indexuZy7x5W3BsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/security/index.vue")
  },
  {
    name: "account-theme",
    path: "/account/theme",
    meta: indexjYAK66wxExMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/theme/index.vue")
  },
  {
    name: "account-user-info",
    path: "/account/user-info",
    meta: indexP3CvtyAcu6Meta || {},
    component: () => import("/opt/buildhome/repo/pages/account/user-info/index.vue")
  },
  {
    name: "auth-contact-support",
    path: "/auth/contact-support",
    meta: indexkXii3v7yMHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/contact-support/index.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: indexXDkuNSxKaRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/forgot-password/index.vue")
  },
  {
    name: "auth-sign-in",
    path: "/auth/sign-in",
    meta: index8oOKunnnMCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/sign-in/index.vue")
  },
  {
    name: "auth-sign-up",
    path: "/auth/sign-up",
    meta: indexFFhqQ97vMkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/sign-up/index.vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/opt/buildhome/repo/pages/donate/index.vue")
  },
  {
    name: "financial-history-buy-sell",
    path: "/financial-history/buy-sell",
    component: () => import("/opt/buildhome/repo/pages/financial-history/buy-sell/index.vue")
  },
  {
    name: "financial-history-convert",
    path: "/financial-history/convert",
    component: () => import("/opt/buildhome/repo/pages/financial-history/convert/index.vue")
  },
  {
    name: "financial-history-deposit-withdraw",
    path: "/financial-history/deposit-withdraw",
    component: () => import("/opt/buildhome/repo/pages/financial-history/deposit-withdraw/index.vue")
  },
  {
    name: "financial-history-donation",
    path: "/financial-history/donation",
    component: () => import("/opt/buildhome/repo/pages/financial-history/donation/index.vue")
  },
  {
    name: "financial-history-fan-apps",
    path: "/financial-history/fan-apps",
    component: () => import("/opt/buildhome/repo/pages/financial-history/fan-apps/index.vue")
  },
  {
    name: "financial-history-prize",
    path: "/financial-history/prize",
    component: () => import("/opt/buildhome/repo/pages/financial-history/prize/index.vue")
  },
  {
    name: "financial-history-qr",
    path: "/financial-history/qr",
    component: () => import("/opt/buildhome/repo/pages/financial-history/qr/index.vue")
  },
  {
    name: "financial-history-stake",
    path: "/financial-history/stake",
    component: () => import("/opt/buildhome/repo/pages/financial-history/stake/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index/index.vue")
  },
  {
    name: "markets-marketName",
    path: "/markets/:marketName()",
    component: () => import("/opt/buildhome/repo/pages/markets/[marketName]/index.vue")
  },
  {
    name: "markets",
    path: "/markets",
    component: () => import("/opt/buildhome/repo/pages/markets/index.vue")
  },
  {
    name: "overlayContent",
    path: "/overlayContent",
    component: () => import("/opt/buildhome/repo/pages/overlayContent/index.vue")
  },
  {
    name: "wallet",
    path: "/wallet",
    component: () => import("/opt/buildhome/repo/pages/wallet/index.vue")
  }
]