<template>
  <div>
    <p-header />
    <div class="container p-account-layout">
      <div v-if="$device.isDesktop" class="row">
        <div class="col-md-3 p-account-layout__content">
          <user-details size="md" class="mb-32" />
          <side-menu />
        </div>
        <div class="col-1">
          <p-divider :vertical="true" />
        </div>
        <div class="col-md-8 p-account-layout__content">
          <slot />
        </div>
      </div>
      <div v-else class="p-account-layout">
        <div class="p-account-layout__content">
          <slot />
        </div>
      </div>
    </div>
    <p-footer />
  </div>
</template>

<script setup lang="ts">
import PHeader from '../PHeader/index.vue'

import PFooter from '@/layouts/PFooter/index.vue'
const { $device } = useNuxtApp()
</script>
