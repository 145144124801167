import { defineAsyncComponent } from 'vue'
export default {
  "p-account": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/PAccount/PAccount.vue").then(m => m.default || m)),
  "p-auth-header": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/PAuth/Header.vue").then(m => m.default || m)),
  "p-auth": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/PAuth/PAuth.vue").then(m => m.default || m)),
  "p-footer-link-items": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/PFooter/LinkItems/index.vue").then(m => m.default || m)),
  "p-footer-paribu-self": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/PFooter/ParibuSelf/index.vue").then(m => m.default || m)),
  "p-footer-social-medias": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/PFooter/SocialMedias/index.vue").then(m => m.default || m)),
  "p-footer": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/PFooter/index.vue").then(m => m.default || m)),
  "p-header-mobile-navigation": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/PHeader/MobileNavigation/index.vue").then(m => m.default || m)),
  "p-header": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/PHeader/index.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/default.vue").then(m => m.default || m)),
  empty: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/empty.vue").then(m => m.default || m))
}