import en from './en.json'
import tr from './tr.json'

export default defineI18nConfig(() => {
  const messages = { en, tr }
  const locale = import.meta.env.VITE_DEFAULT_LOCALE
  return {
    legacy: false,
    locale,
    fallbackLocale: 'tr',
    messages,
    warnHtmlMessage: false,
    missingWarn: false,
    fallbackWarn: false,
    setLocaleMessages: (newMessages: Record<string, string>) => {
      const { $i18n } = useNuxtApp()
      $_.merge(messages[locale], newMessages)
      $i18n.setLocaleMessage(locale, messages[locale])
      $i18n.locale.value = locale
    },
  }
})
